<script setup lang="ts">
import {computed, PropType} from 'vue';
import {Link} from '@inertiajs/vue3'

const {type, classButton, href, disabled, color, size} = defineProps({
    type: {type: String, default: 'submit'},
    classButton: {default: '', type: [String, Object, Array] as PropType<string | Record<string, boolean> | any[]>,},
    href: {type: String, default: '#'},
    target: {type: String, default: '_self'},
    disabled: Boolean,
    color: {type: String, default: 'bg-primary hover:bg-primary-400'},
    style: {type: String, default: ''},
    size: {type: String, default: 'md'}
});

const class_size = computed(() => {
    switch (size) {
        case 'xs':
            return 'py-1 px-1 text-xs';
        case 'sm':
            return 'py-1.5 px-2 text-sm';
        case 'md':
            return 'py-2 px-4 text-md';
        case 'lg':
            return 'py-3 px-6 text-lg';
        case 'xl':
            return 'py-4 px-9 text-xl';
        default:
            return 'py-2 px-4 text-md';
    }
})

const default_class_primary = "rounded-sm text-white transition-all duration-250 " +
    "focus:ring-1 focus:ring-primary-800 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed";

const link_array_type: Array<string> = ['link', 'href'];
</script>

<template>
    <template v-if="link_array_type.includes(type) && !disabled">
        <Link :href="href" :target="target">
            <button type="button" :class="[color,class_size,default_class_primary,classButton]" :disabled="disabled">
                <slot>
                </slot>
            </button>
        </Link>
    </template>
    <template v-else-if="type === 'a'">
        <a :href="href" :target="target">
            <button type="button" :class="[color,class_size,default_class_primary,classButton]" :disabled="disabled">
                <slot>
                </slot>
            </button>
        </a>
    </template>
    <template v-else>
        <button :type="type" :class="[color,class_size,default_class_primary,classButton]" :disabled="disabled" :style="style">
            <slot>
            </slot>
        </button>
    </template>
</template>
