<script setup>
import DsPophover from "~templates/components/pophover/ds-popover.vue";
import DsButton from "~templates/components/form/button/ds-button.vue";
import {createApp, ref, useSlots} from "vue";
import {DsAlertConfirm} from "~/composable/modal-confirm";

const props = defineProps({
  classButton: {
    type: String || Object || Array,
    default: ''
  },
  classOption: {
    type: String || Object || Array,
    default: ''
  },
  submitOnYes: {
    type: Boolean,
    default: false
  },
  width: {
    string: String, default: 'w-52'
  },
  buttonCol: {
    string: Boolean, default: false
  },
  modal: {
    type: Boolean,
    default: false
  },
  modalTitle: {
    type: String,
    default: undefined
  }
});

const slots = useSlots();
const emit = defineEmits(['click-yes', 'click-no']);

function actionYes() {
  emit('click-yes');
}

function actionNo() {
  emit('click-no');
}

const open = ref(false);

const modalContent = ref(null);
const modalYesButton = ref(null);
const modalNoButton = ref(null);
async function openModal() {
  let text_modal = "Êtes-vous sûr ?";
  let text_button_yes = "Oui";
  let text_button_no = "Non";
  if (slots['text']) {
    text_modal = modalContent.value.innerHTML;
  }
  if (slots['yes-text']) {
    text_button_yes = modalYesButton.value.innerHTML;
  }
  if (slots['no-text']) {
    text_button_no = modalNoButton.value.innerHTML;
  }
  const confirm = await DsAlertConfirm(props.modalTitle, text_modal, text_button_no,text_button_yes);

  if (confirm) {
    actionYes();
    return;
  }

  actionNo();
}
</script>

<template>
  <ds-pophover :class-button="`outline-none ${width}  ${classButton}`" v-if="!modal"
               :class-option="`border-t-0 border-2 ${classOption}`">
    <template #arrow>
      <span></span>
    </template>
    <template #button-prefix="{open}">
      <slot name="button-prefix" :open="open">

      </slot>
    </template>
    <template #button="{open}">
      <slot name="button" :open="open">

      </slot>
    </template>
    <template #option="{close}">
      <p class="text-center cursor-default text-xs md:text-sm">
        <slot name="text">
          Êtes-vous sûr ?
        </slot>
      </p>
      <div :class="['grid gap-4 mt-4',{'grid-cols-2':!buttonCol}]">
        <ds-button type="button" color="bg-red-500 hover:bg-red-400 py-3 uppercase" size="sm" @click="actionNo();close()">
          <slot name="no-text">
            Non
          </slot>
        </ds-button>
        <ds-button :type="submitOnYes ? 'submit' : 'button'" color="bg-emerald-500 hover:bg-emerald-400 py-3 uppercase" size="sm" @click="actionYes();close()">
          <slot name="yes-text">
            Oui
          </slot>
        </ds-button>
      </div>
    </template>
  </ds-pophover>
  <div :class="`outline-none  ${width}  ${classButton}`" v-else>
    <slot name="button-prefix" :open="open">

    </slot>
    <span @click="openModal">
        <slot name="button" :open="open">
        </slot>
    </span>
    <div ref="modalContent" class="hidden">
      <slot name="text">
        Êtes-vous sûr ?
      </slot>
    </div>
    <div ref="modalYesButton" class="hidden">
      <slot name="yes-text">

      </slot>
    </div>
    <div ref="modalNoButton" class="hidden">
      <slot name="no-text">

      </slot>
    </div>
  </div>
</template>

<style scoped>

</style>
