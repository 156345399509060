<script setup lang="ts">
import {DsUser} from "~/helper/User/DsUser";
import SidebarItem from "~templates/layouts/sidebar/sidebar-item.vue";
import {sidebar_open} from "~/composable/layout/sidebar";
import {defineAsyncComponent, ref} from "vue";
import SidebarCategory from "~templates/layouts/sidebar/sidebar-category.vue";
import TicketModalCreate from "~templates/pages/ticket/_components/ticket-modal-create.vue";
import {open_ticket} from "~/pages/tickets/tickets";

const DsModal = defineAsyncComponent(() => import('~templates/components/pophover/ds-modal.vue'));

const default_shop_slug = DsUser.getUser('default_shop_slug') ?? null;
const default_shop_lang_slug = DsUser.getUser('default_shop_lang_slug') ?? null;

const modal_tools = ref(false);

const userRoute = [
    {
        route: ['user.index', 'user.set-role'],
        name: 'Gestion',
        icon: 'fa-solid fa-users',
        role: 'user'
    },
    {
        route: ['user.role.list', 'user.role.set-user'],
        name: 'Roles',
        icon: 'fa-solid fa-key',
        role: 'roles.manage'
    },
    {
        route: ['user.notification.create'],
        name: 'Notifications',
        icon: 'fa-solid fa-bell',
        role: 'dash.notification'
    }
]

const timeclockRoute = [
    {
        route: ['timeclock.manage.index'],
        name: 'Calendrier',
        icon: 'fa-regular fa-calendar-days',
    },
    {
        route: ['timeclock.manage.summary'],
        name: 'Récap.',
        icon: 'fa-solid fa-book',
    }
]

const logistics_route = [
    {
        route: ['logistics.stock.index', 'logistics.stock.location-info', 'logistics.stock.location-info-scallog'],
        name: 'Gestion des stocks',
        icon: 'fa-solid fa-map-location-dot',
    },
    {
        route: ['logistics.reception.index', 'logistics.reception.supplier-order'],
        name: 'Réception',
        icon: 'fa-solid fa-truck-ramp-box',
        role: 'logistics.reception'
    },
    {
        route: ['logistics.restock.index', 'logistics.restock.form', 'logistics.restock.scallog-form'],
        name: 'Réappro.',
        icon: 'fa-solid fa-dolly',
        role: 'logistics.restock'
    },
    {
        route: ['logistics.inventory.index', 'logistics.inventory.form'],
        name: 'Inventaire',
        icon: 'fa-solid fa-database',
        role: 'logistics.inventory'
    },
    {
        route: ['logistics.picking.index', 'logistics.picking.form'],
        name: 'Sortie',
        icon: 'fa-solid fa-boxes-packing',
        role: 'logistics.picking'
    },
    // {
    //   route: ['logistics.printer.history', 'logistics.printer.manage'],
    //   name: 'Imprimante',
    //   icon: 'fa-solid fa-print',
    //   role: 'logistics.printer'
    // },
    {
        route: ['logistics.stock.recap'],
        name: 'Information',
        icon: 'fa-solid fa-circle-info',
        role: 'logistics.stock.recap'
    }
]

const scallog_route = [
    {
        route: ['scallog.order.list'],
        name: 'Commande',
        icon: 'fa-solid fa-layer-group',
        role: 'scallog.order'
    },
    {
        route: ['scallog.inventory.list'],
        name: 'Inventaire',
        icon: 'fa-solid fa-database',
        role: 'scallog.inventory'
    },
    {
        route: ['scallog.get-out.list'],
        name: 'Vague de sortie',
        icon: 'fa-solid fa-boxes-packing',
        role: 'scallog'
    }
];

const cockpitRoute = [
    {
        route: ['cockpit.index'],
        name: 'Monitoring',
        icon: 'fa-solid fa-satellite-dish',
        role: 'dev.cockpit'
    },
    {
        route: ['cockpit.ai.index'],
        name: 'Ai test',
        icon: 'fa-solid fa-brain',
        role: 'dev.cockpit'
    },
]
const mediaRoute = [
    {
        route: ['media.index'],
        name: 'media',
        icon: 'fa-regular fa-image',
        role: 'media'
    },
    {
        route: ['media.form'],
        name: 'media',
        icon: 'fa-solid fa-plus',
        role: 'media'
    },
]

const open_timeout = ref(null);
const sidebar = ref(null);

function openSidebar(event) {
    clearTimeout(open_timeout.value);
    if (sidebar_open.value) {
        return;
    }
    open_timeout.value = setTimeout(() => {
        if (sidebar.value && sidebar.value.contains(event.target)) {
            sidebar_open.value = true;
        }
    }, 250);
}

function handleMouseLeave() {
    clearTimeout(open_timeout.value);
    sidebar_open.value = false;
}
</script>

<template>
    <!--    <div :class="['transition-all',!sidebar_open ? 'md:w-10 w-0' : 'md:w-40 w-0']">-->
    <!--    </div>-->
    <div class="md:w-10 w-0">

    </div>
    <div ref="sidebar"
         :class="['bg-white flex flex-col items-center transition-all text-sm h-screen fixed z-30 overflow-x-hidden md:border-r md:border-gray-300',
    !sidebar_open ? 'md:w-10 w-0':'md:w-52 w-2/3 ']" @mouseenter="openSidebar($event)" @mouseleave="handleMouseLeave">
        <p class="h-10 select-none  w-full flex mr-4 relative hover:text-primary-600 pb-1 mb-1 ">
            <span class="my-auto mx-auto  text-sm md:text-xs text-gray-500" v-if="sidebar_open">Tableau de bord</span>
        </p>
        <div class="w-full h-full flex flex-col justify-center overflow-y-auto overflow-x-hidden  ">

            <sidebar-category :route="userRoute" name="Utilisateurs" icon="fa-solid fa-user"
                              v-if="DsUser.haveRole('user')"/>

            <sidebar-item route="tickets.dashboard" icon="fa-solid fa-bugs" name="Tickets"
                          v-if="DsUser.haveRole('tickets')"></sidebar-item>

            <sidebar-category name="Logistique" icon="fa-solid fa-dolly" :route="logistics_route"
                              v-if="DsUser.haveOneRole('logistics')"/>

            <sidebar-category name="Scallog" icon="icon-scallog" :route="scallog_route"
                              v-if="DsUser.haveOneRole('scallog')"/>

            <sidebar-category name="Pointage" icon="fa-regular fa-clock" :route="timeclockRoute"
                              v-if="DsUser.haveRole('timeclock')"/>

            <sidebar-item route="category.index"
                          :route-param="{shop_slug:default_shop_slug,lang_slug:default_shop_lang_slug}"
                          icon="fa-solid fa-folder" name="Categories" v-if="DsUser.haveRole('dev')"></sidebar-item>
            <sidebar-category name="Logistique" icon="fa-solid fa-dolly" :route="logistics_route"
                              v-if="DsUser.haveOneRole('stock')"/>


            <sidebar-item route="product.index"
                          :route-param="{shop_slug:default_shop_slug,lang_slug:default_shop_lang_slug}"
                          icon="fa-solid fa-boxes-stacked" name="Produits" v-if="DsUser.haveRole('dev')"></sidebar-item>
            <sidebar-item route="cms.shortlink.list" icon="fa-solid fa-paint-roller" name="CMS"
                          v-if="DsUser.haveRole('cms','cms.shortlink')"></sidebar-item>

            <sidebar-item route="media.index" icon="fa-regular fa-image" name="Média"
                          v-if="DsUser.haveRole('dev')"></sidebar-item>
            <div class="mt-auto">
            </div>


            <button type="button" @click="open_ticket = true"
                    :class="['flex pl-3 pr-2 py-1 transition group duration-250 cursor-pointer hover:bg-primary-500 ']">
                <i class="fa-solid fa-circle-plus text-lg mr-3 text-primary-500 group-hover:text-white "></i>
                <span class="flex w-full my-auto truncate overflow-hidden">
          <span class="my-auto text-primary-500 group-hover:text-white">Nouveau ticket</span>
        </span>
            </button>

            <ticket-modal-create v-model="open_ticket"/>

            <a href="https://wiki.drift.tools/shelves" target="_blank"
               :class="['flex pl-3 pr-2 py-1 transition group duration-250 cursor-pointer hover:bg-green-500 ']">
                <i class="fa-brands fa-wikipedia-w text-lg mr-3 text-green-500 group-hover:text-white "></i>

                <span class="flex w-full my-auto">
            <span class="my-auto  text-green-500 group-hover:text-white">Wiki</span>
        </span>
            </a>
            <sidebar-category name="Cockpit" icon="fa-solid fa-screwdriver-wrench" :route="cockpitRoute"
                              v-if="DsUser.haveOneRole('dev.cockpit')"/>


            <!--         Bouton pour DsWindow. Pour le moment ne sert a rien  -->
            <!--            <button @click="modal_tools = !modal_tools"-->
            <!--                    :class="['flex pl-3 pr-2 py-1 transition group duration-250 cursor-pointer hover:bg-primary-200 hover:text-primary-950']">-->
            <!--                <i class="fa-solid fa-screwdriver-wrench text-lg mr-3"></i>-->

            <!--                <span class="flex w-full my-auto">-->
            <!--                <span class="my-auto"><slot>Outils</slot></span>-->
            <!--            </span>-->
            <!--            </button>-->

        </div>
    </div>
    <transition>
        <div class="bg-black/60 md:bg-black/20 fixed z-[29] w-screen h-screen right-0 " v-if="sidebar_open"
             @click="sidebar_open = !sidebar_open">

        </div>
    </transition>
    <!--    <ds-modal v-model="modal_tools">-->
    <!--        <template #title>-->
    <!--                    <span class="text-xl">-->
    <!--                    <i class="fa-solid fa-screwdriver-wrench  mr-4"></i> Outils divers-->
    <!--                    </span>-->
    <!--        </template>-->
    <!--        <template #content>-->
    <!--            <div class="w-full p-4 @container">-->
    <!--                <div class="grid xl:grid-cols-3 gap-2 grid-cols-2 text-center">-->
    <!--                    <div class="group cursor-pointer"-->
    <!--                         @click="modal_tools = false;DsWindow.openWindow('notes','Outils de test')">-->
    <!--                        <img src="/images/ds-window/notes.webp" alt="notes"-->
    <!--                             class="rounded-xl border-4 border-gray-400 group-hover:saturate-150 transition-all">-->
    <!--                        <span class="group-hover:text-blue-500 underline-offset-4 transition-all">Outils de test</span>-->
    <!--                    </div>-->

    <!--                    <div class="group cursor-pointer" v-if="DsUser.haveRole('tools.calc-hours')"-->
    <!--                         @click="modal_tools = false;DsWindow.openWindow('calc_hours','Calculatrice d\'horaire')">-->
    <!--                        <img src="/images/ds-window/calc-hour.webp" alt="calc-hour"-->
    <!--                             class="rounded-xl border-4 border-gray-400 group-hover:saturate-150 transition-all">-->
    <!--                        <span class="group-hover:text-blue-500 underline-offset-4 transition-all">Calculatrice d'horaire</span>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </template>-->
    <!--    </ds-modal>-->
</template>

<style scoped>
</style>
